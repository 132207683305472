import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import styles from './style.module.css'

/**
 * @type {React.ForwardRefExoticComponent<SectionPropTypes & React.RefAttributes<HTMLElement>>}
 */
const Section = forwardRef(({ id, children, className, style }, ref) => {
  return (
    <section
      style={style}
      id={id}
      className={`${styles.section} ${className || ''}`}
      ref={ref}
    >
      {children}
    </section>
  )
})

Section.displayName = 'Section'

/**
 * @typedef {Object} SectionPropTypes
 * @property {String} id
 * @property {String} className
 * @property {React.ReactNode} children
 */
Section.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
}

export default Section
