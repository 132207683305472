import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import LandingModule from '@modules/Landing'
import { initializeApollo, addApolloState } from '@utils/apollo'
import { getSkinQuizQuestion } from '@gql/quizQuery'

function LandingPage() {
  return <LandingModule />
}

/**
 * getStaticProps
 *
 * @param {import('next').GetStaticPropsContext} ctx
 * ```
 * // Example for getStaticProps
 *
 * // if you are in /product/:id,
 * // then params will be { id: productId }
 * ctx.params = { paramsKey: value }
 *
 * // 'en' or 'id', depends on current language
 * ctx.locale = localeValue
 *
 * // ['en','id']
 * ctx.locales = localeValue[]
 *
 * // using defaultLocale from next config
 * ctx.defaultLocale = defaultLocaleValue
 * ```
 *
 * @returns {import('next').GetStaticPropsResult} pageProps
 * ```
 * return value must be object with:
 * {}.props // props that will be send to this page component
 * {}.revalidate // for incremental static regeneration, read more in this https://nextjs.org/docs/basic-features/data-fetching#incremental-static-regeneration
 * {}.notFound // boolean value to return notFound page if value is true
 * ```
 */
export async function getStaticProps(ctx) {
  const client = initializeApollo(undefined, ctx)

  await client.query({
    query: getSkinQuizQuestion,
    context: {
      headers: { 'content-language': ctx.locale },
    },
  })

  return addApolloState(client, {
    props: {
      ...(await serverSideTranslations(ctx.locale, [
        'common',
        'landing',
        'faq',
        'products',
      ])),
    },
    revalidate: 60 * 60, // 1 hour
  })
}

LandingPage.getLayout = LandingModule.getLayout

export default LandingPage
