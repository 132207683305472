import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'

import { SEO, LazyLoad } from '@components'
import CommonPageLayout from '@layouts/CommonPageLayout'

import Hero from './sections/Hero'
// import Products from './sections/Products'
// import HowItWorks from './sections/HowItWorks'
import Contacts from './sections/Contacts'

import styles from './style.module.css'

import { useIsWidthLarger } from '@utils/hooks'
import ProductCategories from './sections/ProductCategories'

const Ingredients = dynamic({
  loader: () => import('./sections/Ingredients'),
  ssr: false,
})

const BaseInTheNews = dynamic({
  loader: () => import('./sections/BaseInTheNews'),
  ssr: false,
})

const OurPromise = dynamic({
  loader: () => import('./sections/OurPromise'),
  ssr: false,
})

const WhyYouNeedIt = dynamic({
  loader: () => import('./sections/WhyYouNeedIt'),
  ssr: false,
})

const FirstQuestion = dynamic({
  loader: () => import('./sections/FirstQuestion'),
  ssr: false,
})

const FAQ = dynamic({
  loader: () => import('./sections/FAQ'),
  ssr: false,
})

// const BasedOnYou = dynamic({
//   loader: () => import('./sections/BasedOnYou'),
//   ssr: false,
// })

// const TakeTheQuiz = dynamic({
//   loader: () => import('./sections/TakeTheQuiz'),
//   ssr: false,
// })

// const EmailNewsletter = dynamic({
//   loader: () => import('./sections/EmailNewsletter'),
//   ssr: false,
// })

const VeganHalal = dynamic({
  loader: () => import('./sections/VeganHalal'),
  ssr: false,
})

const ItsMyBase = dynamic({
  loader: () => import('./sections/ItsMyBase'),
  ssr: false,
})

const BestSeller = dynamic({
  loader: () => import('./sections/BestSeller'),
  ssr: false,
})

const ProductShowcase = dynamic({
  loader: () => import('./sections/ProductShowcase'),
  ssr: false,
})

const VeganBeauty = dynamic({
  loader: () => import('./sections/VeganBeauty'),
  ssr: false,
})

function LandingModule() {
  const { t, i18n } = useTranslation(['landing', 'faq', 'products'])
  const isMedium = useIsWidthLarger(768)
  const isLarge = useIsWidthLarger(960)
  const isXlarge = useIsWidthLarger(1200)
  const isXxlarge = useIsWidthLarger(1600)
  return (
    <>
      <SEO
        title='BASE - #1 Vegan Beauty Brand in Indonesia'
        description='BASE, Pilihan Vegan Terbaik: Skincare bahan alami dengan teknologi tinggi, efektif menyempurnakan keunikan kulitmu tanpa bahan kimia berbahaya.'
      />

      <Hero t={t} i18n={i18n} isMedium={isMedium} />

      <LazyLoad style={{ height: '900vh' }}>
        <BestSeller isXxlarge={isXxlarge} isMedium={isMedium} t={t}/>
        <ProductCategories isMedium={isMedium} t={t}/>
        <ProductShowcase isMedium={isMedium} t={t} />
        {/* <Products t={t} isMedium={isMedium} /> */}
        <FirstQuestion t={t} />
        <VeganBeauty isXlarge={isXlarge} isLarge={isLarge} isMedium={isMedium} t={t} />
        <Ingredients t={t} />
        {/* <HowItWorks t={t} /> */}
        <BaseInTheNews t={t} />
        <OurPromise t={t} />
        <WhyYouNeedIt isMedium={isMedium} t={t} />
        <FAQ t={t} i18n={i18n} />
        {/* <BasedOnYou t={t} /> */}
        {/* <TakeTheQuiz t={t} /> */}
        <VeganHalal t={t} />
        {/* <EmailNewsletter t={t} /> */}
        <ItsMyBase t={t} isMedium={isMedium} />
      </LazyLoad>

      <Contacts isMedium={isMedium} />
    </>
  )
}

LandingModule.getLayout = page => {
  return (
    <CommonPageLayout containerClassName={styles.container}>
      {page}
    </CommonPageLayout>
  )
}

export default LandingModule
