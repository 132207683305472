import Image from 'next/legacy/image'

import { Section, Text, Link } from '@components'

import styles from './style.module.css'

function Contacts({ isMedium }) {
  return (
    <Section className={styles.section}>
      <div id="contact-us" className={styles.container}>
        <div className={styles.socialLeft}>
          <div className={styles.icon}>
            <Image
              alt="base contact whatsapp"
              src="https://static.base.co.id/new-whatsapp-logo.svg"
              height={isMedium ? 47 : 24}
              width={isMedium ? 47 : 24}
            />
          </div>

          <div className={styles.textContainer}>
            <Text variant="lightTitle" className={styles.textCenter}>
              Whatsapp
            </Text>
            <Text variant="lightTitle" className={styles.textCenterBrown}>
              <Link externalLink="https://wa.me/+6281808110888" target="blank">
                +62 81-8081-10888
              </Link>
            </Text>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.social}>
          <div className={styles.icon}>
            <Image
              alt="base contact email"
              src="https://static.base.co.id/new-mail-logo.svg"
              height={ isMedium ? 31 : 14}
              width={ isMedium ? 46 : 21}
            />
          </div>
          <div className={styles.textContainer}>
            <Text variant="lightTitle" className={styles.textCenter}>
              Email
            </Text>
            <Text variant="lightTitle" className={styles.textCenter}>
              <Link externalLink="mailto:hello@base.co.id" target="blank">
                hello@base.co.id
              </Link>
            </Text>
          </div>

        </div>
      </div>
    </Section>
  )
}

export default Contacts
