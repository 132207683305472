import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { getHostname } from '@utils/helpers'

function SEO({
  title,
  description,
  image,
  url,
  keywords,
  noIndex,
  jsonSchema,
  openGraphProduct,
}) {
  const router = useRouter()
  const hostname = getHostname()
  const path = router.asPath.replace(/\?.*/, '')

  return (
    <Head>
      <title key="title-tag">{title}</title>

      {/* No Index */}
      {noIndex && (
        <meta key="robots" name="robots" content="noindex,nofollow" />
      )}

      {/* Canonical */}
      {!noIndex && hostname && path.includes('blog') && (
        <link key="canonical" rel="canonical" href={`${hostname}${path}`} />
      )}

      {/* jsonSchema */}
      {Boolean(jsonSchema) && (
        <Script
          id="jsonSchema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonSchema) }}
        />
      )}

      {/* Meta title */}
      <meta name="title" content={title} key="meta-title" />
      <meta property="og:title" content={title} key="meta-og-title" />
      <meta name="twitter:title" content={title} key="meta-twitter-title" />

      {/* Meta description */}
      <meta name="description" content={description} key="meta-desc" />
      <meta
        property="og:description"
        content={description}
        key="meta-og-desc"
      />
      <meta
        name="twitter:description"
        content={description}
        key="meta-twitter-desc"
      />

      {/* Meta image */}
      <meta property="og:image" content={image} key="meta-og-image" />
      <meta name="twitter:image" content={image} key="meta-twitter-image" />

      {/* Meta url */}
      <meta property="og:url" content={url} key="meta-og-url" />

      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@itsmybase" />

      {/* Meta keywords */}
      {keywords && <meta name='keywords' content={keywords} key='meta-keywords' />}

      {/* Meta OpenGraph */}
      {openGraphProduct && (
        <>
          <meta property="product:brand" content="BASE" />
          <meta property="product:price:currency" content="IDR" />
          <meta property="product:condition" content="new" />

          {openGraphProduct.price && (
            <meta
              property="product:price:amount"
              content={openGraphProduct.price}
            />
          )}

          {openGraphProduct.availability && (
            <meta
              property="product:availability"
              content={openGraphProduct.availability}
            />
          )}

          {openGraphProduct.itemID && (
            <meta
              property="product:retailer_item_id"
              content={openGraphProduct.itemID}
            />
          )}

          {openGraphProduct.category && (
            <meta
              property="product:category"
              content={openGraphProduct.category}
            />
          )}

          {openGraphProduct.itemGroupID && (
            <meta
              property="product:item_group_id"
              content={openGraphProduct.itemGroupID}
            />
          )}
        </>
      )}
    </Head>
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  keywords: PropTypes.string,
  noIndex: PropTypes.bool,
  jsonSchema: PropTypes.object,
  openGraphProduct: PropTypes.object,
}

SEO.defaultProps = {
  title: 'Skincare Vegan Lokal Terbaik - Base',
  description: 'Personalized skincare formulated',
  image: 'https://static.base.co.id/thumbnail-default.png',
  url: 'https://www.base.co.id',
  noIndex: false,
  jsonSchema: null,
  openGraphProduct: null,
}

export default SEO
