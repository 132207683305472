import { memo } from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'

import styles from './style.module.css'

/**
 * @typedef {React.FunctionComponent<ButtonPropTypes & HTMLLinkElement & HTMLButtonElement>} BoxButton
 */
function BoxButton({
  color,
  link,
  externalLink,
  className,
  hasPulse,
  disabled,
  onClick,
  type,
  children,
  ...props
}) {
  const classNames =
    `${styles.button} ` +
    `${styles[color]} ` +
    `${disabled ? styles.disabled : ''} ` +
    `${className || ''}`

  if (link) {
    return (
      <Link
        href={!disabled ? link : '#0'}
        className={classNames}
        onClick={!disabled ? onClick : undefined}
        {...props}
      >
        {children}
        {hasPulse && <span className={styles.pulse} />}
      </Link>
    )
  }

  if (externalLink) {
    return (
      <a
        href={!disabled ? externalLink : '#0'}
        className={classNames}
        onClick={!disabled ? onClick : undefined}
        {...props}
      >
        {children}
        {hasPulse && <span className={styles.pulse} />}
      </a>
    )
  }

  return (
    <button
      type={type}
      className={classNames}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      {...props}
    >
      {children}
      {hasPulse && <span className={styles.pulse} />}
    </button>
  )
}

/**
 * @typedef {Object} ButtonPropTypes
 * @property {String} color
 * @property {String} link
 * @property {String} externalLink
 * @property {String} className
 * @property {Boolean} hasPulse
 * @property {Boolean} disabled
 * @property {Function} onClick
 * @property {'button' | 'reset' | 'submit'} type
 * @property {React.ReactNode} children
 */
BoxButton.propTypes = {
  color: PropTypes.string,
  link: PropTypes.string,
  externalLink: PropTypes.string,
  className: PropTypes.string,
  hasPulse: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  children: PropTypes.node,
}

BoxButton.defaultProps = {
  color: 'primary',
  type: 'button',
}

/**
 * @type {React.MemoExoticComponent<BoxButton>}
 */
export default memo(BoxButton)
