import { useState, useCallback, useEffect, memo } from 'react'
import Image from 'next/legacy/image'
import PropTypes from 'prop-types'

import styles from './style.module.css'

import { useCSSScrollSnapModule, useIsWidthLarger } from '@utils/hooks'

function CarouselHero({
  name,
  className,
  childrenClassName,
  color,
  arrowStyle,
  arrowPosition,
  overrideActiveChild,
  showArrow,
  customSelector,
  onMoveChild,
  children,
  style,
}) {
  useCSSScrollSnapModule()
  const isMedium = useIsWidthLarger(768)
  const [activeChild, setActiveChild] = useState(overrideActiveChild || 0)
  const [xPos, setXPos] = useState(0)

  const moveToChild = useCallback(
    (index, childID) => {
      const childEl = document.getElementById(childID)

      setActiveChild(index)
      if (onMoveChild) onMoveChild(index)
      if (childEl) {
        childEl.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        })
      }
    },
    [onMoveChild]
  )

  const touchMoveHandler = useCallback(
    childID => {
      const childEl = document.getElementById(childID)

      if (childEl) {
        const contentEl = document.getElementById(`${name}_content`)
        const contentRect = contentEl.getBoundingClientRect()
        const childRect = childEl.getBoundingClientRect()

        const elementXPos = contentRect.x - childRect.x
        setXPos(elementXPos)
      }
    },
    [name]
  )

  const touchEndHandler = useCallback(
    (index, childID) => {
      const childEl = document.getElementById(childID)

      if (childEl) {
        const snapScroll = Math.floor(childEl.clientWidth / 2)

        if (
          Math.abs(xPos) >= snapScroll &&
          xPos > 0 &&
          index !== children.length
        ) {
          setActiveChild(index + 1)
        }

        if (Math.abs(xPos) >= snapScroll && xPos < 0 && index !== 0) {
          setActiveChild(index - 1)
        }
      }
    },
    [children.length, xPos]
  )

  const arrowClick = value => {
    const carouselItemLength = children.length
    const nextChild = activeChild + value

    if (nextChild > carouselItemLength - 1) {
      return moveToChild(0, `${name}_child_${0}`)
    }

    if (nextChild < 0) {
      return moveToChild(
        carouselItemLength - 1,
        `${name}_child_${carouselItemLength - 1}`
      )
    }

    return moveToChild(nextChild, `${name}_child_${nextChild}`)
  }

  const renderArrow = () => {
    const arrow = {
      round: {
        black: 'https://static.base.co.id/arrow-icon-round.svg',
        'night-rider': 'https://static.base.co.id/arrow-icon-round.svg',
        'color-grey': 'https://static.base.co.id/arrow-icon-round.svg',
        white: 'https://static.base.co.id/arrow-icon-round.svg',
      },
      scroll: {
        black: 'https://static.base.co.id/scroll-right-black.svg',
        'night-rider': 'https://static.base.co.id/scroll-right-night-rider.svg',
        'color-grey': 'https://static.base.co.id/scroll-right-night-rider.svg',
        white: 'https://static.base.co.id/scroll-right-white.svg',
      },
      default: {
        black: 'https://static.base.co.id/chevron-black.svg',
        'night-rider': 'https://static.base.co.id/chevron-night-rider.svg',
        'color-grey': 'https://static.base.co.id/chevron-color-grey.png',
        white: 'https://static.base.co.id/chevron-white.svg',
      },
    }

    return arrow[arrowStyle][color]
  }

  useEffect(() => {
    if (
      typeof overrideActiveChild === 'number' &&
      overrideActiveChild !== activeChild
    ) {
      moveToChild(overrideActiveChild, `${name}_child_${overrideActiveChild}`)
    }
  }, [name, activeChild, overrideActiveChild, moveToChild])

  let arrowHeight = arrowStyle === 'scroll' ? 23 : 9
  let arrowWidth = arrowStyle === 'scroll' ? 45 : 17

  if (color === 'color-grey') {
    arrowHeight = isMedium ? 40 : 25
    arrowWidth = isMedium ? 30 : 15
  }

  if (arrowStyle === 'round') {
    arrowHeight = isMedium ? 44 : 24
    arrowWidth = isMedium ? 34 : 24
  }

  return (
    <>
      {!isMedium && showArrow && (
        <div className={styles.arrowContainer}>
          <span
            className={`
              ${color === 'color-grey' ? styles.newPosition : ''}
              ${styles.arrowLeft}
              ${styles[arrowStyle]}
              ${arrowPosition ? styles[arrowPosition] : ''}
            `}
            onClick={() => arrowClick(-1)}
          >
            <Image
              src={renderArrow()}
              alt="carousel arrow left"
              layout="fixed"
              objectFit="contain"
              height={arrowHeight}
              width={arrowWidth}
            />
          </span>
          <span
            className={`
              ${color === 'color-grey' ? styles.newPosition : ''}
              ${styles.arrowRight}
              ${styles[arrowStyle]}
              ${arrowPosition ? styles[arrowPosition] : ''}
            `}
            onClick={() => arrowClick(1)}
          >
            <Image
              src={renderArrow()}
              alt="carousel arrow right"
              layout="fixed"
              objectFit="contain"
              height={arrowHeight}
              width={arrowWidth}
            />
          </span>
        </div>
      )}
      {
        !isMedium && (
          <div className={styles.carouselSelector}>
            {children.map((_, index) => {
              const childID = `${name}_child_${index}`

              if (customSelector) {
                return customSelector({
                  index,
                  isActive: activeChild === index,
                  moveToChild: () => moveToChild(index, childID),
                })
              }

              return (
                <div
                  key={`circle_${childID}`}
                  onClick={() => moveToChild(index, childID)}
                  className={`
                    ${styles.circle}
                    ${styles[color]}
                    ${activeChild === index ? styles.active : ''}
                  `}
                />
              )
            })}
          </div>
        )
      }
      <div style={style} className={`${styles.carousel} ${className || ''}`}>
        {isMedium && showArrow && (
          <div className={styles.arrowContainer}>
            <span
              className={`
                ${color === 'color-grey' ? styles.newPosition : ''}
                ${styles.arrowLeft}
                ${styles[arrowStyle]}
                ${arrowPosition ? styles[arrowPosition] : ''}
              `}
              onClick={() => arrowClick(-1)}
            >
              <Image
                src={renderArrow()}
                alt="carousel arrow left"
                layout="fixed"
                objectFit="contain"
                height={arrowHeight}
                width={arrowWidth}
              />
            </span>
            <span
              className={`
                ${color === 'color-grey' ? styles.newPosition : ''}
                ${styles.arrowRight}
                ${styles[arrowStyle]}
                ${arrowPosition ? styles[arrowPosition] : ''}
              `}
              onClick={() => arrowClick(1)}
            >
              <Image
                src={renderArrow()}
                alt="carousel arrow right"
                layout="fixed"
                objectFit="contain"
                height={arrowHeight}
                width={arrowWidth}
              />
            </span>
          </div>
        )}

        <div
          id={`${name}_content`}
          className={`
          ${color === 'color-grey' ? styles.newPadding : ''}
          ${styles.carouselContent}
          `}
        >
          {children.map((child, index) => {
            const childID = `${name}_child_${index}`
            return (
              <div
                id={childID}
                key={childID}
                className={`
                  ${styles.carouselChild}
                  ${childrenClassName || ''}
                `}
                onTouchMove={() => touchMoveHandler(childID)}
                onTouchEnd={() => touchEndHandler(index, childID)}
              >
                {child}
              </div>
            )
          })}
        </div>
        {
          isMedium && <div className={styles.carouselSelectorDesktop}>
            {children.map((_, index) => {
              const childID = `${name}_child_${index}`

              if (customSelector) {
                return customSelector({
                  index,
                  isActive: activeChild === index,
                  moveToChild: () => moveToChild(index, childID),
                })
              }

              return (
                <div
                  key={`circle_${childID}`}
                  onClick={() => moveToChild(index, childID)}
                  className={`
                    ${styles.circle}
                    ${styles[color]}
                    ${activeChild === index ? styles.active : ''}
                  `}
                />
              )
            })}
          </div>
        }
      </div>
    </>
  )
}

CarouselHero.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  childrenClassName: PropTypes.string,
  arrowPosition: PropTypes.oneOf(['top', '']),
  arrowStyle: PropTypes.oneOf(['scroll', 'default', 'round']),
  color: PropTypes.oneOf(['white', 'black', 'night-rider', 'color-grey']),
  overrideActiveChild: PropTypes.number,
  // showSelector: PropTypes.bool,
  showArrow: PropTypes.bool,
  customSelector: PropTypes.func,
  onMoveChild: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
}

CarouselHero.defaultProps = {
  arrowPosition: '',
  arrowStyle: 'default',
  showSelector: true,
  showArrow: true,
  color: 'black',
}

export default memo(CarouselHero)
