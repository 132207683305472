import { useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'
import Image from 'next/legacy/image'
import PropTypes from 'prop-types'

import styles from './style.module.css'

function Modal({ className, showCloseButton, onClose, children, rounded = false, variant, closeStyle = 'black', contentClassName, ...props }) {
  const [mounted, setMounted] = useState(false)

  const handleOutsideClick = useCallback(
    e => {
      const modalViewEl = document.getElementById('modal-view')
      const modalEl = document.getElementById('modal')

      if (modalEl && !modalEl.contains(e.target)) {
        if (children) modalViewEl.style.display = 'none'
        if (onClose) onClose()
      }
    },
    [children, onClose]
  )

  useEffect(() => {
    setMounted(true)
    const modalViewEl = document.getElementById('modal-view')

    if (children) {
      modalViewEl.style.display = 'initial'
      document.addEventListener('click', handleOutsideClick)
    }

    return () => {
      setMounted(false)
      modalViewEl.style.display = 'none'
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [children, handleOutsideClick])

  return mounted
    ? createPortal(
      <div id="modal" className={`${variant ? styles[variant] : styles.modal} ${className || ''}`}>
        <div className={`${styles.modalContent} ${contentClassName}`}>
          {typeof children === 'function' ? children(props) : children}
        </div>
        {showCloseButton && closeStyle === 'black' && (
          <div onClick={onClose} className={rounded ? styles.roundedCloseButton : styles.closeButton}>
            <Image
              src="https://static.base.co.id/clear-black.svg"
              alt="close modal"
              height={20}
              width={20}
            />
          </div>
        )}

        {showCloseButton && closeStyle === 'white' && (
          <div onClick={onClose} className={styles.closeButton}>
            <Image
              src="https://static.base.co.id/icon-close-white.svg"
              alt="close modal"
              height={20}
              width={20}
            />
          </div>
        )}
      </div>,
      document.getElementById('modal-view')
    )
    : null
}

Modal.propTypes = {
  className: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  variant: PropTypes.string,
  closeStyle: PropTypes.string,
  contentClassName: PropTypes.string,
  rounded: PropTypes.bool,
}

Modal.defaultProps = {
  showCloseButton: true,
}

export default Modal
