import useDidMount from './useDidMount'
import { cancelablePromise } from '../helpers'

/**
 * @function useCSSScrollSnapModule
 * custom hook to load css scroll snap polyfills
 *
 * @returns {Void}
 */
function useCSSScrollSnapModule() {
  useDidMount(() => {
    /**
     * Example of dynamic import module
     *
     * so we dont have to load a big unimportant module
     * if most of other page dont need it
     */
    const scrollSnapImport = cancelablePromise(
      import(
        /* webpackChunkName: "scroll-snap-module" */ 'css-scroll-snap-polyfill'
      )
    )

    scrollSnapImport.promise
      .then(module => {
        module.default()
      })
      .catch(error => {
        // Handle if module importing is failed
        // with other than cancelled reason
        if (!error.isCanceled) {
          //
        }
      })

    return () => {
      scrollSnapImport.cancel()
    }
  })
}

export default useCSSScrollSnapModule
