import { useState } from 'react'
import Image from 'next/legacy/image'
import PropTypes from 'prop-types'
import { desktopContent } from '@constants/landing'
import { useReactHTMLParserModule } from '@utils/hooks'

import { Section, Text, BoxButton, Link, Modal, CarouselHero } from '@components'

import styles from './style.module.css'

import { useTracker } from '@utils/trackerContext'

function Hero({ t, isMedium }) {
	const [showModal, setShowModal] = useState(false)
	const tracker = useTracker()
	const HTMLParserModule = useReactHTMLParserModule()

	return (
		<Section className={styles.section} id="hero">
			{/* <div className={styles.container}> */}
			<CarouselHero
				style={!isMedium ? { width: `${desktopContent.length * 100}vw` } : {}}
				name="hero-carousel"
				arrowStyle="round"
				color="black"
				showSelector={false}
				className={styles.carouselDesktop}
				showArrow={true}
				activeChild={0}>
				{
					desktopContent(t).map((content, index) => isMedium ? (
						<div className={styles.heroContainer} key={index}>
							<div className={styles.imageContainer}>
								<Image priority src={content.image} objectPosition={"top"} quality={100} layout="fill" alt={`base-hero-image-${index}`} objectFit="cover" />
								<div className={styles.ctaContainer}>
									{content.titleImage && (
										<div className={styles.heroImageContainer1}>
											<Image
												alt={content.titleImageAlt}
												src={content.titleImage}
												layout="fill"
												objectFit="contain"
											/>
										</div>
									)}
									{/* {content.titleImage2 && (
										<div className={styles.heroImageContainer2}>
											<Image
												alt={content.titleImageAlt}
												src={content.titleImage2}
												layout="fill"
												objectFit="contain"
											/>
										</div>
									)} */}
									<Text className={styles.titleHero} color={content.colorMode === 'light' ? 'white' : 'black'} variant="title">
										{content.title}
									</Text>
									{content.subtitle && <Text className={styles.subtitleHero} color={content.colorMode === 'light' ? 'white' : 'black'} variant="title">
										{content.subtitle}
									</Text>}
									<Text color={content.colorMode === 'light' ? 'white' : 'black'} className={styles.descriptionHero} variant="description">
										{HTMLParserModule && HTMLParserModule.default(content.description)}
									</Text>
									<BoxButton
										color="primaryShadow"
										className={styles.newCta}
										link={content.productSlug && `/products${content.productSlug}`}
										externalLink={content.link || null}
										target={content.link && '_blank'}
										>
										{content.buttonText? content.buttonText: t('landing:hero.cta')}
									</BoxButton>
								</div>
							</div>
						</div>
					) : (
						<div
							key={index}
							style={{ backgroundColor: content.colorCode }}
							className={styles.imageContainer}>
							<div className={styles.heroMobileImageWrapper}>
								<Image
									priority
									src={content.imageMobile}
									alt="skin test launch promo"
									className={styles.heroMobileImage}
									layout="fill"
									objectFit="cover"
									objectPosition={"top"}
								/>
							</div>
							<div className={styles.ctaContainer}>
								{
									content.titleImage && (
										<div className={styles.heroImageWrapperMobile}>
											{content.titleImage && (
												<div className={`${styles.heroImageContainer1} ${content.colorMode == "light" && styles.bannerMobile} ${!content.titleImage2 && styles.default}`}>
													<Image
														alt={content.titleImageAlt}
														src={content.titleImageMobile}
														layout="fill"
														objectFit="contain"
													/>
												</div>
											)}
											{/* {content.titleImage2 && (
												<div className={styles.heroImageContainer2}>
													<Image
														alt={content.titleImageAlt}
														src={content.titleImage2}
														layout="fill"
														objectFit="contain"
													/>
												</div>
											)} */}
										</div>
									)
								}
								{content.title && <Text className={`${styles.titleHero} ${!content.titleImage && styles.noMargin}`} variant="title">
									{content.title}
								</Text>}
								{content.subtitle && <Text className={styles.subtitleHero} variant='title'>{content.subtitle}</Text>}
								<Text className={styles.descriptionHero} variant="description">
									{HTMLParserModule && HTMLParserModule.default(content.description)}
								</Text>
								<BoxButton
									color="primaryShadow"
									className={styles.newCta}
									link={`/products${content.productSlug}`}>
									{content.buttonText? content.buttonText: t('landing:hero.cta')}
								</BoxButton>
							</div>
						</div>
					))
				}
			</CarouselHero>

			{showModal && (
				<Modal
					key={isMedium}
					onClose={() => setShowModal(false)}
					className={styles.popUpModal}
					showCloseButton
					rounded
				>
					<Link
						onClick={() => {
							tracker.trackEvent('popup_banner', {
								event_category: 'popup_banner',
							})
						}}
						link="/products"
						target="_blank"
						rel="noreferrer noopener"
						className={styles.popUpLink}
					>
						{isMedium ? (
							<Image
								src="https://static.base.co.id/PopUp%20Banner%20SpayLater.jpg"
								alt="10.10 Promo"
								placeholder="blur"
								blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPsrAcAAZcBCvVKh9YAAAAASUVORK5CYII="
								priority
								height={600}
								width={600}
								className={styles.popUpImageDesktop}
							/>
						) : (
							<Image
								src="https://static.base.co.id/PopUp%20Banner%20SpayLater.jpg"
								alt="10.10 Promo"
								placeholder="blur"
								blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPsrAcAAZcBCvVKh9YAAAAASUVORK5CYII="
								priority
								fill
								layout="fill"
								objectFit='cover'
								className={styles.popUpImageMobile}
							/>
						)}
					</Link>

					<div className={styles.popUpButton}>
						<BoxButton color="primary" link="/products"
							className={styles.buttonPopUp}>
							{t('landing:button.popup')}
						</BoxButton>
					</div>
				</Modal>
			)}
		</Section>
	)
}

Hero.propTypes = {
	t: PropTypes.func.isRequired,
	// i18n: PropTypes.object.isRequired,
	isMedium: PropTypes.bool,
}

export default Hero
