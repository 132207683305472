import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'

/**
 * @type {React.ForwardRefExoticComponent<LazyLoadPropTypes & React.RefAttributes<HTMLElement>>}
 */
const LazyLoad = forwardRef(({ style, children }, ref) => {
  const [load, setLoad] = useState(false)

  const containerRef = useRef()

  useImperativeHandle(ref, () => containerRef.current)

  useEffect(() => {
    const scrollListener = () => {
      const containerEl = containerRef.current

      if (containerEl) {
        const elBound = containerEl.getBoundingClientRect()

        if (
          elBound.top >= 0 &&
          elBound.left >= 0 &&
          elBound.top < window.innerHeight &&
          elBound.left < window.innerWidth
        ) {
          setLoad(true)
        }
      }
    }

    if (!load) {
      scrollListener()

      window.addEventListener('scroll', scrollListener, { passive: true })

      return () => {
        window.removeEventListener('scroll', scrollListener)
      }
    } else {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [load, style])

  return load ? children : <div ref={containerRef} style={style} />
})

LazyLoad.displayName = 'LazyLoad'

/**
 * @typedef LazyLoadPropTypes
 * @property {React.CSSProperties} style
 * @property {React.ReactNode} children
 */
LazyLoad.propTypes = {
  style: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default LazyLoad
