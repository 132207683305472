import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import PageContainer from '@components/PageContainer'

const FABWidget = dynamic(() =>
  import(
    /* webpackChunkName: "layout-widget" */
    '../../components/FAB'
  )
)

const Footer = dynamic(() =>
  import(
    /* webpackChunkName: "layout-footer" */
    '../../components/Footer'
  )
)

function CommonPageLayout({
  containerClassName,
  footerColor,
  footerClassName,
  withWidget,
  withFooter,
  children,
}) {
  return (
    <>
      <PageContainer className={containerClassName}>
        {children}

        {withWidget && <FABWidget />}
      </PageContainer>
      {withFooter && (
        <Footer textColor={footerColor} className={footerClassName} />
      )}
    </>
  )
}

CommonPageLayout.propTypes = {
  containerClassName: PropTypes.string,
  footerColor: PropTypes.string,
  footerClassName: PropTypes.string,
  withWidget: PropTypes.bool,
  withFooter: PropTypes.bool,
  children: PropTypes.node,
}

CommonPageLayout.defaultProps = {
  withWidget: true,
  withFooter: true,
}

export default CommonPageLayout
