import { useState, useEffect } from 'react'
import { cancelablePromise } from '../helpers'

/**
 * @function useReactHTMLParserModule
 * custom hook to return module of react html parser async
 *
 * @returns {import('react-html-parser')|null}
 */
function useReactHTMLParserModule() {
  const [ReactHTMLParserModule, setReactHTMLParserModule] = useState(null)

  useEffect(() => {
    const reactHTMLParserImport = cancelablePromise(
      import(
        /* webpackChunkName: "react-html-parser-module" */ 'react-html-parser'
      )
    )

    reactHTMLParserImport.promise
      .then(module => {
        setReactHTMLParserModule({
          default: module.default,
          processNodes: module.processNodes,
          convertNodeToElement: module.convertNodeToElement,
        })
      })
      .catch(error => {
        if (!error.isCanceled) {
          //
        }
      })

    return () => {
      reactHTMLParserImport.cancel()
    }
  }, [])

  return ReactHTMLParserModule
}

export default useReactHTMLParserModule
