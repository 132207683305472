import app from '@config/app'

function getHostname() {
  switch (app.environment) {
    case 'production':
      return 'https://www.base.co.id'
    case 'development':
      return 'https://dev.www.base.co.id'
    default:
      return 'http://localhost:3000'
  }
}

export default getHostname
