import { BoxButton, Section } from '@components'
import styles from './style.module.css'
import Image from 'next/legacy/image'

function ProductCategories({ t }) {
  const ProductCategoriesData = [
    {
      title: 'NEW!',
      description: t('landing:product-category.content.0.text'),
      image: 'https://storage.googleapis.com/base-lp-assets/Bodyscrub%20NEW-%20DTC.jpg',
      link: '/products?sort=new',
    },
    {
      title: 'Body Care',
      description: t('landing:product-category.content.1.text'),
      image: 'https://storage.googleapis.com/base-lp-assets/DTC%20-%20BodyCare%20new.jpg',
      link: '/products?category=KBC',
    },
    {
      title: 'Skincare',
      description: t('landing:product-category.content.2.text'),
      image: 'https://storage.googleapis.com/base-lp-assets/product-categories-skincare.png',
      link: '/products?category=KSC',
    },
    {
      title: 'Makeup',
      description: t('landing:product-category.content.3.text'),
      image: 'https://storage.googleapis.com/base-lp-assets/product-categoriess-makeup.jpeg',
      link: '/products?category=KCC',
    },
  ]
  return (
    <Section className={styles.container} id="product-categories">
      <div className={styles.productCategoriesContainer}>
        <div className={styles.titleBar}>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>{t('landing:product-category.title')}</h2>
            <p className={styles.description}>{t('landing:product-category.subtitle')}</p>
          </div>
          <div className={styles.ctaContainer}>
            <p className={styles.descriptionCta}>{t('landing:product-category.notes')}</p>
            <BoxButton link='/quiz/intro' color='primaryShadow' className={styles.cta}>
              <div className={styles.searchWhite}>
                <Image
                  src="https://static.base.co.id/search-white.svg"
                  alt="skincare vegan halal"
                  height={20}
                  width={20}
                />
              </div>
              Skin Test
            </BoxButton>
          </div>
        </div>
        <div className={styles.contentContainer}>
          {ProductCategoriesData.map((productCategory, i) => (
            <div key={i} className={styles[`productCategoryCard${i}`]}>
              <div className={styles.productCategoryImageContainer}>
                <Image
                  alt="Base Product"
                  src={productCategory.image}
                  layout="fill" />
              </div>
              <div className={styles.productCategoryInfo}>
                <p className={styles.productCategoryTitle}>{productCategory.title}</p>
                <p className={styles.productCategoryDescription}>{productCategory.description}</p>
                <BoxButton link={productCategory.link} color='transparentWhiteBorder' className={styles.productCategoryCta}>
                  {t('landing:product-category.cta')}
                  <div className={styles.arrowImage}>
                    <Image
                      src={"https://static.base.co.id/arrow-right-white.svg"}
                      alt="skincare vegan halal"
                      height={16}
                      width={16}
                    />
                  </div>
                </BoxButton>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.ctaContainerMobile}>
          <BoxButton link='/quiz/intro' color='primaryShadow' className={styles.cta}>
            <div className={styles.searchWhite}>
              <Image
                src="https://static.base.co.id/search-white.svg"
                alt="skincare vegan halal"
                height={20}
                width={20}
              />
            </div>
            Skin Test
          </BoxButton>
          <p className={styles.descriptionCtaMobile}>{t('landing:product-category.notes')}</p>
        </div>
      </div>
    </Section>
  )
}

export default ProductCategories
