import { memo } from 'react'
import PropTypes from 'prop-types'

import styles from './style.module.css'

function PageContainer({ className, children }) {
  return (
    <div
      className={`
        ${styles.page} 
        ${className || ''}
      `}
    >
      {children}
    </div>
  )
}

PageContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default memo(PageContainer)
