import { memo } from 'react'
import NextLink from 'next/link'
import PropTypes from 'prop-types'

import styles from './style.module.css'

function Link({
  children,
  link,
  externalLink,
  className,
  target,
  onClick,
  withUnderline,
}) {
  const classNames =
    `${styles.link} ` +
    `${withUnderline ? styles.withUnderline : ''} ` +
    `${className || ''}`

  return link && !externalLink
    ? (
      (<NextLink onClick={onClick} href={link} passHref target={target} className={classNames}>

        {children}

      </NextLink>)
    )
    : (
      <a onClick={onClick} href={externalLink} target={target} className={classNames} rel='noreferrer'>
        {children}
      </a>
    )
}

Link.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string,
  externalLink: PropTypes.string,
  className: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  withUnderline: PropTypes.bool,
}

export default memo(Link)
